@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "W2STBold";
  src: url("./assets/fonts/Bold.ttf");
  font-style: normal;
}

@font-face {
  font-family: "W2STCondensed";
  src: url("./assets/fonts/Condensed.ttf");
  font-style: normal;
}

@font-face {
  font-family: "W2STExtraBold";
  src: url("./assets/fonts/ExtraBold.ttf");
  font-style: normal;
}

@font-face {
  font-family: "W2STLight";
  src: url("./assets/fonts/Light.ttf");
  font-style: normal;
}

@font-face {
  font-family: "W2STMedium";
  src: url("./assets/fonts/Medium.ttf");
  font-style: normal;
}

@font-face {
  font-family: "W2STThin";
  src: url("./assets/fonts/Thin.ttf");
  font-style: normal;
}

@font-face {
  font-family: "W2STUltraBold";
  src: url("./assets/fonts/UltraBold.ttf");
  font-style: normal;
}

@font-face {
  font-family: "W2STUltraLight";
  src: url("./assets/fonts/UltraLight.ttf");
  font-style: normal;
}

.W2STBold {
  font-family: "W2STBold";
}

.W2STCondensed {
  font-family: "W2STCondensed";
}

.W2STExtraBold {
  font-family: "W2STExtraBold";
}

.W2STLight {
  font-family: "W2STLight";
}

.W2STMedium {
  font-family: "W2STMedium";
}

.W2STThin {
  font-family: "W2STThin";
}

.W2STUltraBold {
  font-family: "W2STUltraBold";
}

.W2STUltraLight {
  font-family: "W2STUltraLight";
}

body {
  margin: 0;
  font-family: "W2STLight", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

.font-bold {
  font-family: "W2STBold", sans-serif !important;
}

input:focus, textarea:focus {
  outline: none;
  border: 1px solid #8874FF;
}

.react-international-phone-input {
  font-family: "Manrope", sans-serif !important;
}

.react-international-phone-input-container .react-international-phone-input {
  display: block !important;
  width: 100% !important;
  padding: 30px !important;
  border: 1px solid #d3d3d3 !important;
  font-size: 16px !important;
  font-family: "Manrope", sans-serif !important;
}

.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  padding: 30px !important;
  border: 1px solid #d3d3d3 !important;
}

.loader {
  display: flex;
  margin: 0 auto;
  width: 3.5em;
  height: 3.5em;
  border: 3px solid transparent;
  border-top-color: #8874FF;
  border-bottom-color: #8874FF;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

.loader:before {
  content: "";
  display: block;
  margin: auto;
  width: 0.75em;
  height: 0.75em;
  border: 3px solid #8874FF;
  border-radius: 50%;
  animation: pulse 1s alternate ease-in-out infinite;
}

.authscreen {
  background-color: #0f0068 !important;
  width: 100vw;
  height: 100vh;
}

.w2st-button {
  padding: 10px 30.5px;
  background-color: #0f0068;
  border: 1px solid #8874ff;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
}

.embed-background {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.9)
    ),
    url("./assets/images/embed.png");
  background-size: cover;
  border-radius: 15px;
  padding: 40px;
}

.w2st-light-button {
  padding: 10px 30.5px;
  background-color: #F3F1FF;
  border-radius: 12px;
  color: #3F2ABA;
  cursor: pointer
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
}
